@import url(spacing.css);
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.CardWidth {
  width: 650px !important;
}

.MuiAutocomplete-popper {
  z-index: 9 !important;
}

.MuiPickersPopper-root {
  z-index: 9 !important;
}

.form-control {
  font-weight: 500;
  font-family: "Poppins";
}

.job_search_box .job_search {
  width: 100%;
  border: 0;
  font-size: 16px;
}

.job_search_box .job_search:focus-visible {
  outline: 0;
}

.job_search_box input::placeholder {
  font-size: 16px !important;
  color: #848484;
  font-family: "Poppins";
}

.job_location_box .loca_job {
  width: 200px;
}

.loca_job .MuiSelect-icon {
  top: calc(34% - 0.2em) !important;
  right: 8px !important;
}

.employer_client_input .MuiFormControl-root {
  width: 100%;
}

.ql-container {
  font-family: "Poppins" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.react_editor .ql-container .ql-editor {
  font-size: 16px !important;
  font-family: "Poppins !important";
}

.invoice_date .MuiStack-root > .MuiTextField-root {
  min-width: 100% !important;
}

.invoice_date .MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #121212 !important;
  font-family: "Poppins" !important;
}

.peritemview span {
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  font-family: "Poppins";
}

.MuiFormLabel-root {
  font-size: 16px !important;
  font-weight: 300 !important;
  color: #121212 !important;
  font-family: "Poppins" !important;
}

.lables .MuiList-root.MuiList-padding.css-h4y409-MuiList-root {
  display: flex;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 15px;
}

.lables .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: transparent !important;
}

.lables span {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
}

.paginations
  .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-rounded.Mui-selected.MuiPaginationItem-page.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: #fff !important;
  box-shadow: 0px 2px 6px 0px #00000026;
}

.paginations
  .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {
  display: none !important;
}

.paginations
  button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-rounded.MuiPaginationItem-previousNext.css-10w330c--MuiPaginationItem-root {
  display: none !important;
}

.paginations .MuiPagination-ul.css-wjh20t-MuiPagination-ul {
  justify-content: center;
  padding-bottom: 25px;
}

/* .formCHeck svg  {
    fill: none;
} */
.aligns .css-onkv84-MuiStack-root {
  align-items: end !important;
}

.message {
  text-align: center;
}

.formCHeck .css-12wnr2w--MuiCheckbox-root {
  color: #cacaca;
}

.formCHeck .css-12wnr2w-Checkbox-root.Mui-checked,
.css-12wnr2w-Checkbox-root.MuiCheckbox-indeterminate {
  color: #eea23d;
}

.lables p {
  font-size: 14px;
  color: #ffa500;
  font-weight: 400;
  padding-left: 16px;
  margin: 0px;
}

iframe {
  border: 0;
}

.attachments span {
  color: #eea23d !important;
}

.attachments small {
  display: none !important;
}

.attachments svg path {
  fill: #eea23d !important;
}

.attachments button {
  background: #eea23d52 !important;
}

.attachments {
  border-bottom: 1px solid #cacaca;
  padding-bottom: 30px;
}

.contentSvg .bookmark span {
  color: #274593 !important;
}

.shh path {
  fill: #274593 !important;
}

.shh {
  color: #274593 !important;
}

.jobCards {
  box-shadow: 0px 15px 40px 0px #0000000d;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
}

.activemenu li a.active {
  background: #d5e3f7 !important;
}

.activemenu li a.active:after {
  content: "";
  height: 100%;
  position: absolute;
  width: 4px;
  right: 0px;
  top: 0px;
  background: #274593 !important;
}

.talendBtn .css-1yo28cw-MuiChip-root.MuiChip-root {
  padding-left: 0px;
  padding-right: 16px;
  background: transparent;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  color: #848484;
}

span.MuiTouchRipple-root.css-8je8zh-MuiTouchRipple-root {
  display: none;
}

.venderSpan {
  font-weight: 500;
}

.message p {
  color: #274593 !important;
  font-weight: 400;
}

.SelectDropdown {
  padding-top: 22px;
  border-top: 1px solid #cacaca;
}

.SelectDropdown .MuiFormControl-root.css-ptt77l-MuiFormControl-root {
  width: 100% !important;
}

.Tenderinput .MuiFormControl-root.MuiTextField-root {
  width: 100%;
  background: #f0f0f0;
  border-radius: 10px;
  height: 39px;
  padding-left: 13px;
  margin-top: 10px;
  padding-top: 4px;
}

.Tenderinput .MuiInput-underline:before {
  border-bottom: 0 !important;
}

.SelectDropdown label + .css-hrjh4n-MuiInputBase-root {
  margin-top: 12px;
  font-family: "Poppins";
}

.SelectDropdown label {
  color: #121212;
  font-size: 16px;
  font-weight: 300;
}

.lables li {
  padding: 0 4px !important;
}

.formCHeck .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root {
  display: block;
}

.SelectDropdown select#demo-customized-select-native {
  background: #f0f0f0;
  border: 0;
  border-radius: 10px;
  font-size: 16px;
  color: #121212;
  font-weight: 500;
}

header {
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.05));
  background: #fff;
  padding: 10.5px 0px;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 4;
}

.navbar-brand {
  display: inline-block;
  width: 17%;
}

.menu {
  margin: 0px;
  padding: 0px;
}

.menu li {
  list-style: none;
  display: inline-block;
  padding: 0px 8px;
}

.menu li:last-child {
  padding-right: 0px;
}

.menu li a {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: 0.03em;

  color: #121212;
  display: block;
}

.menu li a:hover {
  color: #eea23d;
}

.outline-button {
  border: 1px solid #274593 !important;
  border-radius: 73px !important;
  padding: 10px 30px;
  color: #274593 !important;
  /* font-size: 16px !important; */
  letter-spacing: 0.03em !important;
  font-family: "Bahnschrift" !important;
  /* font-weight: 600 !important; */
}

.outline-login {
  background: #eea23d !important;
  border-radius: 73px !important;
  padding: 10px 30px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #121212 !important;
  font-family: "Bahnschrift" !important;
  letter-spacing: 0.03em !important;
}

.footer {
  padding: 52px 0px 41px 0px;
  background: #fff;
}

.copy-right {
  color: #121212;
  font-size: 16px;
  letter-spacing: 0.03em;
  font-weight: 400;
}

.footer-menu {
  padding: 10px 15px;
}

.border-left {
  border-left: 1px solid #cacaca;
}

.footer-menu ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-menu ul li {
  display: inline-block;
  padding: 0px 15px;
}

.footer-menu ul li a {
  display: block;
  color: #121212;
  font-size: 16px;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 0.03rem;
}

.footer-menu ul li a:hover {
  color: #eea23d;
}

.social-icons {
  margin: 8px 0 0 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social-icons li {
  list-style: none;
  display: inline-block;
  padding: 0px 20px;
}

.footer .css-ldbyt5-MuiStack-root {
  justify-content: space-between;
}

.venderbtn {
  background: #274593 !important;
  color: #fff !important;
  box-shadow: 0px 2px 15px 2px rgba(0, 44, 106, 0.35) !important;
}

.forgots span {
  color: #848484;
  font-size: 16px;
  cursor: pointer;
  font-weight: 300;
}

.register {
  background: url("../images/yellow-bg.png") no-repeat center top;
  margin-top: 68px;
  background-size: 100% 100%;
}

.savedjobs span.MuiChip-label.MuiChip-labelMedium.css-6od3lo-MuiChip-label {
  padding-left: 6px !important;
}

.menu.false svg path {
  fill: #1c1b1f !important;
}

.add-content p {
  font-size: 12px;
  color: #848484;
  margin-top: 0;
  padding-bottom: 16px;
}

.saved-jobs
  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-15arr0g-MuiButtonBase-root-MuiIconButton-root {
  justify-content: center;
  position: relative !important;
  left: 13px !important;
}

.vendor {
  background: url("../images/imgs.png") no-repeat center top !important;
  margin-top: 68px;
  background-size: 100% 100% !important;
}

.content-box h5 {
  font-weight: 700;
  font-size: 45px;
  margin: 0px 0px 5px;
  color: #000000;
  padding-bottom: 12px;
}

.content-box p {
  margin: 0px;
  font-weight: 700;
  font-size: 28px;
  padding-bottom: 40px;
  color: #000000;
}

.register-title h6 {
  font-weight: 400;
  font-size: 28px;
  margin: 0px;
}

.register-des > a {
  border: 2px solid #cacaca;
  border-radius: 88px;
  display: block;
  color: #000;
  text-decoration: none;
  padding: 12px 40px;
  cursor: pointer;
  margin-bottom: 16px;
}

.register-des > a:last-child {
  margin-bottom: 0px;
  cursor: pointer;
}

.register-title span {
  color: #121212;
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins";
  opacity: 0.5;
}

.register-des > a:hover {
  background: #d5e3f7;
  border: 2px solid #274593;
  box-shadow: 0px 2px 15px 2px rgba(0, 44, 106, 0.35);
  cursor: pointer;
}

.form-content {
  font-family: "Poppins";
  font-weight: 500;
  color: #121212;
}

.text-gray {
  color: #848484;
  font-weight: 300;
}

.form-control {
  height: 45px;
  width: 100%;
  background: #f9f9f9;
  border-radius: 4px;
  border: 0px;
  padding: 6px 12px;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  color: #000;
}

.showpassword {
  position: relative;
}

.showpassword .password_eye {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 15px;
  top: 10px;
}

.spaceor {
  text-align: center;
  background: #cacaca;
  height: 1px;
  position: relative;
}

.spaceor span {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 5px 15px;
  color: #848484;
  font-weight: 300;
  letter-spacing: 0.02em;
  white-space: nowrap;
}

.box-shadow {
  box-shadow: 0px 2px 15px 2px rgba(255, 165, 0, 0.35);
}

.bottombar {
  text-align: center;
  margin: 10px 0px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.02rem;
  color: #848484;
  font-family: "Poppins";
}

.bottombar a {
  display: inline-block;
  margin-left: 5px;
  color: #eea23d;
  text-decoration: none;
}

.user-details h1 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.03em;
  margin: 16px 0px 16px;
}

.user-details p {
  font-weight: 400;
  font-size: 14px;

  letter-spacing: 0.03em;
  color: #121212;
  font-family: "Poppins";
  margin: 0px;
  opacity: 0.5;
}

.dashedborder {
  border: 2px dashed #cacaca;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  color: #000000;
  padding: 15px;
}

.sidebar-menu {
  margin: 0px;
  padding: 0px 0px 0px 16px;
}

.sidebar-menu li {
  list-style: none;
  padding: 5px 0px;
  display: block;
}

.sidebar-menu li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;

  font-weight: 400;
  color: #121212;
  font-family: "Poppins";
  padding: 10px 16px;
  white-space: nowrap;
  position: relative;
}

.sidebar-menu li a .menu-icon {
  display: inline-flex;
  width: 30px;
  align-items: center;
}

.sidebar-menu li a.active {
  background: #feefd3;
  border-radius: 27px 0px 0px 27px;
  position: relative;
  font-weight: 500;
}

.sidebar-menu li a.active:after {
  content: "";
  height: 100%;
  position: absolute;
  width: 4px;
  right: 0px;
  top: 0px;
  background: #eea23d;
}

.sidebar-menu li a:hover {
  background: #feefd3;
  border-radius: 27px 0px 0px 27px;
}

.activemenu li a:hover {
  background: #d5e3f7 !important;
}

.activemenu li a:hover:after {
  background: #274593 !important;
}

.sidebar-menu li a:hover:after {
  content: "";
  height: 100%;
  position: absolute;
  width: 4px;
  right: 0px;
  top: 0px;
  background: #eea23d;
}

hr {
  border-bottom: 1px solid #cacaca !important;
  height: 1px !important;
  padding-top: 12px !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

.logout {
  padding-left: 32px;
  position: absolute;
  bottom: 93px;
  left: 0;
  right: 0px;
}

.logout a {
  color: #eea23d;
  text-decoration-line: none;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 400;
}

.logout-icon {
  display: inline-flex;
  align-items: center;
}

.searchbox {
  border: 1px solid #cacaca;
  border-radius: 50px;
  padding: 10px 10px 10px 40px;
  outline: none;
  width: 365px;
  background: #fff url("../images/search.png") no-repeat 14px 8px;
  box-sizing: border-box;
}

.headding {
  font-weight: 600;
  font-size: 28px;
  color: #121212;
  margin: 0px 0px 24px;
}

.step-continer svg.Mui-active,
.step-continer svg.Mui-completed {
  color: #feefd3;
  width: 34px;
  height: 34px;
}

.step-continer text.MuiStepIcon-text {
  fill: #121212;
  font-family: "Bahnschrift";
  font-weight: 600;
  font-size: 17px;
}

.step-continer .MuiStepLabel-labelContainer .Mui-active,
.step-continer .MuiStepLabel-labelContainer .Mui-completed {
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  position: relative;
  top: 2px;

  color: #121212;
}

.step-continer .Mui-active .MuiStepConnector-line {
  border-top-width: 2px;
  border-color: #eea23d;
}

.step-continer .Mui-disabled svg {
  width: 34px;
  height: 34px;
  fill: #f0f0f0;
}

.step-continer .Mui-disabled .MuiStepLabel-labelContainer .Mui-disabled {
  color: #848484;
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  position: relative;
  top: 2px;
}

.according-content-box h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  letter-spacing: 0.02em;

  color: #121212;
}

.according-content ul {
  list-style: none;
  padding-left: 35px;
}

.according-content ul li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  padding: 5px 0px;
  color: #121212;
}

.border-top {
  border-top: 1px solid #f0f0f0;
}

.additional-box label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
}

.arrow-color {
  color: #848484;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: rotate(0deg);
}

.arrow-color.active {
  transform: rotate(180deg);
}

.d-inline-flex {
  display: inline-flex !important;
}

.later {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #eea23d;
  cursor: pointer;
}

.add-content h2 {
  font-size: 28px;
  margin: 0;
  color: #121212;
  font-weight: 600;
}

.add-form-control {
  background: #f0f0f0;
  border-radius: 10px;
  outline: none;
  border: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  height: 38px;
  letter-spacing: 0.02em;

  box-sizing: border-box;
  color: #121212;
  padding: 6px 12px;
  width: 100%;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70% !important;
}

.add-content label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;

  color: #848484;
}

.form-control-area {
  background: #f0f0f0;
  border-radius: 10px;
  outline: none;
  border: 0px;
  resize: none;
  width: 100%;
  height: 90px;
  padding: 6px 12px;
  font-family: "Poppins";
  font-size: 14px;
  box-sizing: border-box;
}

.description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: 0.01em;

  color: #121212;
}

.modal-box .MuiPaper-root {
  border-radius: 10px;
  padding: 30px;
  width: 470px;
}

.dropimg-userprofile {
  border: 2px dashed #cacaca;
  border-radius: 4px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  color: #000000;
  padding: 0px 25px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
}

.dropimg-userprofile p {
  margin: 0px;
}

.dropimg-userprofile p span {
  color: #eea23d;
  font-weight: 600;
}

.text-resume {
  font-weight: 500;
  font-size: 16px;

  color: #121212;
}

.text-worry {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  text-align: center;
  letter-spacing: 0.01em;

  color: #848484;
}

.text-worry span {
  color: #eea23d;
}

.social-icons li a {
  color: #274593;
}

.social-icons li a:hover {
  color: #506ebb;
}

.css-z7uhs0-MuiStepConnector-line {
  border-top-width: 2px !important;
}

.form-control-area::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #121212;
  opacity: 0.5;
}

.form-control-area::-moz-placeholder {
  /* Firefox 19+ */
  color: #121212;
  opacity: 0.5;
}

.form-control-area:-ms-input-placeholder {
  /* IE 10+ */
  color: #121212;
  opacity: 0.5;
}

.form-control-area:-moz-placeholder {
  /* Firefox 18- */
  color: #121212;

  opacity: 0.5;
}

.saved-jobs h2 {
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #121212;
}

.fillterbox {
  font-family: "Poppins" !important;

  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;

  letter-spacing: 0.02em !important;

  color: #121212;
}

.chipBox {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.chipBox li {
  display: inline-block;
  padding-right: 16px;
}

.bookmark {
  text-align: center;
  color: #eea23d;
}

.bookmark span {
  display: block;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.02em;

  /* Yellow #FFA500 */
  color: #eea23d;
}

.pricebox {
  text-align: center;
}

.pricebox h4 {
  margin: 0;
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  letter-spacing: 0.02em;
  white-space: nowrap;

  color: #eea23d;
}

.pricebox h4 small {
  display: inline-block;
  margin-right: 3px;
}

.pricebox span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 10px;

  color: #121212;
}

.description h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.02em;

  color: #121212;
  margin: 0px 0px 10px 0px;
}

.description p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.02em;

  color: #121212;
  margin: 0px;
}

.description {
  margin-bottom: 16px;
}

.textdes {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.02em;
}

.textdes span {
  font-weight: 600;
}

.pricebox h4 small.year {
  display: block;
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.02em;

  color: #848484;
}

.w-price {
  width: 100px;
  white-space: nowrap;
}

.searchmessage {
  padding: 14px 14px 16px 30px;
}

.chat-search {
  border: 1px solid #cacaca;
  border-radius: 50px;
  padding: 10px 10px 10px 40px;
  outline: none;
  width: 100%;
  background: #fff url("../images/search.png") no-repeat 14px 8px;
  box-sizing: border-box;
}

.leftchatbox {
  border-right: 1px solid #cacaca;
}

.chatbox ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.chatbox ul li {
  padding-left: 30px;
  padding-right: 5px;
  padding-top: 10px;
}

.chatuser-text h3 {
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #121212;
  display: flex;
}

.chatuser-text h3 small {
  color: #274593;
  display: inline-block;
  margin-left: 5px;
}

.chatuser-text h6 {
  margin: 0px;
  font-weight: 600;
  font-size: 14px;

  color: #121212;
}

.chatuser-text p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;

  color: #121212;
  opacity: 0.7;
  margin: 2px 0px 0px;
}

span.count {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: #274593;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  font-size: 9px;
}

.chatbox ul li:hover {
  background: #ecf2fb;
  cursor: pointer;
}

.chatbox ul li.active {
  background: #ecf2fb;
  cursor: pointer;
}

.chatbox {
  height: calc(100vh - 220px);
}

.buttonbox {
  text-transform: capitalize !important;
  color: #274593 !important;
  font-family: "Poppins" !important;

  font-weight: 400 !important;
  font-size: 10px !important;
}

.buttonbox p {
  margin: 0px;
}

.headerbox h3 {
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin: 0 0px 3px 0px;
}

.headerbox p {
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
  color: #121212;
  opacity: 0.5;
}

.message-header {
  border-bottom: 1px solid #cacaca;
}

.message-text {
  background: #f0f0f0;
  border-radius: 20px 20px 0px 20px;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #121212;
}

.message-tex-2 {
  background: #f0f0f0;
  border-radius: 20px 20px 20px 0px;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #121212;
}

.meassagebox {
  padding: 15px;
}

.message-text {
  display: inline-block;
}

.message-text span {
  color: #848484;
}

.message-text p {
  margin: 0;
}

.message-text h4 {
  margin: 0px;
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #121212;
}

.text-inline {
  display: inline-flex;
  align-items: flex-end;
}

.text-right {
  text-align: right;
}

.meassagebox {
  height: calc(100vh - 305px);
}

.chatinput {
  display: flex;
  align-items: center;
  width: 100%;
}

.chatinput textarea {
  width: 100%;
  resize: none;
  border: 0px;
  outline: none;
  padding: 6px 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #848484;
  height: 40px;
}

.bottomnav {
  border-top: 1px solid #cacaca;
  padding-top: 15px;
}

.w-25 {
  width: 25%;
}

.w-75 {
  width: 75%;
}

@media only screen and (max-width: 992px) {
  .w-25 {
    width: 100%;
  }

  .w-75 {
    width: 100%;
  }

  .w-30 {
    width: 100%;
  }

  .w-70 {
    width: 100% !important;
  }

  .headding {
    font-size: 18px;
  }

  span.later {
    font-size: 14px;
  }

  .add-content h2 {
    font-size: 18px;
  }

  .content-box h5 {
    font-size: 24px;
  }

  .content-box p {
    font-size: 18px;
  }

  .register-title h6 {
    font-size: 16px;
  }

  .register-title span {
    font-size: 16px;
  }

  .step-continer svg.Mui-active,
  .step-continer svg.Mui-completed {
    color: #feefd3;
    width: 20px;
    height: 20px;
  }

  .step-continer .MuiStepLabel-labelContainer .Mui-active,
  .step-continer .MuiStepLabel-labelContainer .Mui-completed {
    font-size: 14px;
    line-height: 16px;
  }

  .step-continer .Mui-disabled .MuiStepLabel-labelContainer .Mui-disabled {
    font-size: 14px;
  }

  .step-continer .Mui-disabled svg {
    width: 20px;
    height: 20px;
  }

  .according-content-box h2 {
    font-size: 14px;
  }
}

.leftside .message-text,
.rightside .message-text {
  position: relative;
}

.leftside .message-text:before {
  position: absolute;
  left: -9px;
  bottom: 0px;
  content: "";
  background: url("../images/left-conver.png") no-repeat center bottom;
  width: 11px;
  height: 11px;
}

.rightside .message-text:before {
  position: absolute;
  right: -9px;
  bottom: 0px;
  content: "";
  background: url("../images/right-conver.png") no-repeat center bottom;
  width: 11px;
  height: 11px;
}

.chart-view h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #121212;
  margin: 0px;
}

.chart-view p {
  margin: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: 0.02em;

  color: #848484;
}

.chart-view h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: right;
  letter-spacing: 0.02em;
  color: #4caf50;
  margin: 0;
}

.chart-space {
  margin-left: -17px;
}

.job-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.01em;

  color: #848484;
}

.usd {
  padding: 8px;
  width: 115px;
  background: #cacaca40;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.02em;
  color: #121212;
  opacity: 0.5;
}

.usdinput {
  background: transparent;
  outline: none;
  border: 0;
  height: 38px;
  padding: 0px 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  margin-right: 5px;
  color: #121212;
  opacity: 0.5;
  width: calc(100% - 190px);
}

.usd-bg {
  background: #f0f0f0;
  border-radius: 10px;
}

.listitems {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.list-content h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.02em;

  /* Black #121212 */
  color: #121212;
  margin: 0;
}

.list-content span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: 0.02em;

  /* Dark grey */
  color: #848484;
}

.list-content h6 {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: 0.02em;

  /* Black #121212 */
  color: #121212;
}

.listitems li {
  position: relative;

  padding: 10px 5px 10px 18px;
  border-bottom: 1px solid #cacaca;
}

.listitems li:before {
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  background: #eea23d;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

.list-button button {
  border: 0px;
  background: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
  text-align: center;
  letter-spacing: 0.02em;

  /* Dark grey */
  color: #848484;
  cursor: pointer;
}

.list-button button span {
  display: block;
  text-align: center;
}

.list-content h6 strong {
  font-weight: 500;
}

.skills-input input[type="text"] {
  background: #ffffff;
  /* Big card shadow */
  border: 0px;
  border-bottom: 2px solid #ccc;
  width: 100%;
  padding: 8px 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  /* Dark grey */
  color: #848484;
  outline: none;
}

.uploadimg {
  text-align: center;
}

.uploadimg img {
  max-width: 100%;
  display: block;
}

.w-100 {
  width: 100%;
}

.color-blue {
  color: #274593;
  font-weight: 500;
}

.docpreveiw {
  width: 150px;
  background: #f0f0f0;
  border-radius: 8px;
  height: 112px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.img-view img {
  width: 150px;
  border-radius: 8px;
}

.sidebar-scroll {
  height: calc(100vh - 135px);
  padding-bottom: 10px !important;
}

body {
  padding: 0px !important;
  /* overflow-y: hidden !important; */
}

@media (min-width: 1536px) {
  .MuiContainer-root {
    max-width: 1780px !important;
  }

  .register {
    min-height: calc(100vh - 222px);
    background-size: cover;
  }
}

@media (max-width: 992px) {
  ul.menu {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 1s,
      opacity 0.5s linear;
  }

  .menu.menu-selected {
    visibility: visible;
    opacity: 1;
  }

  .invoice_date .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 14px !important;
  }

  .MuiFormLabel-root {
    font-size: 14px !important;
  }

  .job_location_box .loca_job {
    width: 200px;
  }

  .job_search_box .job_search {
    width: 100%;
    border: 0;
  }

  .sidebar-scroll {
    margin-top: 20px !important;
  }

  ul.menu li {
    display: block;
    border-top: 1px solid #cacaca;
    padding: 8px 15px;
  }

  ul.menu li:nth-child(4) {
    display: inline-block;
    border: 0;
  }

  ul.menu li:nth-child(5) {
    display: inline-block;
    border: 0;
  }
}

.bluebtn {
  background: #274593 !important;
  color: #fff !important;
}

ul.menu.color-change li a:hover {
  color: #274593;
}

a {
  text-decoration: none;
}

.backappling a {
  color: #848484;
}

.searchfixed {
  position: relative;
}

.searchfixed a {
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 36px;
  left: 0px;
  top: 0;
  border-radius: 50px;
}

.chatbox ul li.jobseekeractive {
  background: #feefd3;
}

.chatbox.jobseekerbox ul li:hover {
  background: #feefd3;
}

.count.jobcount {
  background: #eea23d;
}

input.employersearch {
  padding: 0px;
  background: transparent;
  border: 0;
  width: 65%;
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */
  letter-spacing: 0.03em;

  /* Dark grey */
  color: #848484;
  outline: none;
}

.iconfooter a {
  color: #274593;
}

@media screen and (max-width: 1536px) and (min-width: 1440px) {
  .outline-button {
    border: 1px solid #274593 !important;
    border-radius: 73px !important;
    padding: 6px 20px;
    color: #274593 !important;
    /* font-size: 16px !important; */
    letter-spacing: 0.03em !important;
    font-family: "Bahnschrift" !important;
    /* font-weight: 600 !important; */
  }

  .outline-login {
    background: #eea23d !important;
    border-radius: 73px !important;
    padding: 6px 20px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #121212 !important;
    font-family: "Bahnschrift" !important;
    letter-spacing: 0.03em !important;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
  .outline-button {
    border: 1px solid #274593 !important;
    border-radius: 73px !important;
    padding: 6px 20px;
    color: #274593 !important;
    /* font-size: 16px !important; */
    letter-spacing: 0.03em !important;
    font-family: "Bahnschrift" !important;
    /* font-weight: 600 !important; */
  }

  .outline-login {
    background: #eea23d !important;
    border-radius: 73px !important;
    padding: 6px 20px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #121212 !important;
    font-family: "Bahnschrift" !important;
    letter-spacing: 0.03em !important;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .footer .css-ldbyt5-MuiStack-root {
    display: block !important;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) and (min-width: 520px) {
  .footer .css-ldbyt5-MuiStack-root {
    display: block !important;
    justify-content: space-between;
  }
}

.job-description {
  white-space: pre-line;
}

.close_icon_circle svg {
  color: red;
  margin-left: 5px;
}

.save_btn {
  display: flex;
  justify-content: space-between;
  width: 145px;
  align-items: center;
}

.textEditor {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.accordion-class .MuiAccordionSummary-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_input {
  background: #ffffff;
  border: 1px solid #cacaca;
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  padding: 10px 12px 10px 30px;
  color: #848484;
  font-weight: 400;
  height: 50px;
}

@media screen and (max-width: 480px) {
  .job_location_box .loca_job {
    width: 200px;
  }

  .ql-container {
    font-size: 12px !important;
  }

  .MuiFormLabel-root {
    font-size: 12px !important;
  }

  .job_search_box input::placeholder {
    font-size: 14px !important;
    color: #848484;
  }

  .job_search_box .job_search {
    font-size: 14px;
  }

  .peritemview span {
    font-size: 12px;
  }

  .invoice_date .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 12px !important;
  }
}
