.arrow_box {
  position: relative;
  padding: 10px 20px;
}

main {
  padding: 0px !important;
  margin-top: 0px !important;
}

input::placeholder {
  color: #848484 !important;
  font-weight: 300;
  font-size: 16px;
  font-family: "Poppins";
  letter-spacing: 0.28px;
  opacity: 1 !important;
}

.header .MuiToolbar-root {
  height: 80px;
  display: flex;
  align-items: center;
  min-height: 80px;
  padding-left: 50px;
  padding-right: 50px;
}

.arrow_box:after,
.arrow_box:before {
  right: 50%;
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  transform: translate(50%, 0%);
}

.header .MuiPaper-root.MuiAppBar-root {
  background-color: #274593 !important;
  box-shadow: none !important;
}

.arrow_box:after {
  border-color: rgba(85, 85, 85, 0);
  border-top-color: #fff;
  border-width: 10px;
  margin-top: 0px;
}

.arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #d9d9d9;
  border-width: 10px;
  margin-top: -0px;
}

.columnchart .apexcharts-tooltip {
  transform: translateX(-50px) translateY(-40px);
  overflow: visible !important;
  white-space: normal !important;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.02em;

  /* Black #121212 */

  color: #121212;
}

.columnchart .apexcharts-tooltip span {
  display: block;
}

.smalltext {
  padding: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  text-align: center;

  color: #121212;
}

.search-icon {
  position: absolute;
  left: 8px;
  top: 10px;
  z-index: 1;
  color: #848484;
}

.w-100 {
  width: 100%;
}

.d-inline-flex {
  display: inline-flex;
}

.pagination-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  padding-bottom: 60px;
  padding-top: 20px;
}

.pagination-custom
  .MuiPagination-root.MuiPagination-root
  .MuiPaginationItem-root {
  margin-left: 10px;
  margin-right: 10px;
}

body {
  padding-right: 0px !important;
  overflow-y: visible !important;
}

.scrolltable .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  border-radius: 5px;
  width: 5px;
  height: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrolltable .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 5px;
  height: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d5e3f7;
}

.colorIcon {
  color: #848484;
}

.report h2 {
  margin: 0px 0px 30px;
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;

  color: #121212;
}

.userright h2 {
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  padding-top: 5px;
  color: #121212;
  margin: 0;
}

.userright p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  /* Black #121212 */
  color: #121212;
}

.userright h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;

  color: #121212;
}

.userright ul {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #121212;
}

img {
  max-width: 100%;
}

.uploadbox {
  border: 2px dashed #cacaca;
  border-radius: 10px;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
}

.uploadbox p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  color: #000000;
}

.uploadbox p span {
  color: #274593;
  font-weight: 500;
}

.imageCard {
  width: 168px;
}

.imageCard img {
  border-radius: 10px;
  display: block;
  height: 168px;
  width: 100%;
  object-fit: cover;
}

.imgpost {
  padding-top: 16px;
}

.addbtnlink {
  margin-top: 30px;
}

.pulishbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.dividers_line {
  position: relative;
}

.dividers_line:after {
  content: "";
  position: absolute;
  right: 0px;
  width: 2px;
  height: 20px;
  background: #274593;
  top: 18px;
  opacity: 0.7;
  border-radius: 5px;
}

.dividers_line:last-child:after {
  display: none;
}

.fontWeight {
  font-weight: 600 !important;
}

.reupload {
  border: 2px solid #cacaca;
  border-radius: 10px;
  padding: 10px 20px;
}

.reupload img {
  height: 50px;
}

.ql-snow .ql-editor img {
  max-width: 14% !important;
  float: right;
  top: 5px;
  border-radius: 6px;
}

.ql-editor {
  min-height: 18em;
}

.drag-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #cacaca;
  border-radius: 10px;
  width: 100%;
  height: 104px;
  position: relative;
}

.csvButton {
  height: 54px;
  background: #fff !important;
  border-radius: 73px !important;
  border: solid 1px !important;
  font-size: 16px !important;
  color: #274593 !important;
  padding: 10px 25px !important;
  font-weight: 600 !important;
  white-space: nowrap;
}

.csvButton:hover {
  background: #f7f7f7 !important;
  border-color: #f7f7f7 !important;
}

.resetButton {
  height: 54px;
  background: #274593 !important;
  border-radius: 73px !important;
  border: solid 1px !important;
  font-size: 16px !important;
  color: #fff !important;
  padding: 10px 25px !important;
  font-weight: 600 !important;
  white-space: nowrap;
}

.resetButton:hover {
  background: #274593 !important;
  border-color: #274593 !important;
}

.toggleBtn {
  background: #fff !important;
  border-radius: 73px !important;
  border: solid 1px !important;
  color: #274593 !important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
}

.toggleBtn:hover {
  background: #f7f7f7 !important;
  border-color: #f7f7f7 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.right-pull {
  float: right;
}

@media screen and (max-width: 992px) {
  .MuiDrawer-docked .MuiDrawer-paper {
    display: none;
  }

  .drag-drop {
    height: 81px;
  }

  .sidebar__box nav {
    width: 0px;
    display: contents;
  }

  input::placeholder {
    font-size: 14px;
  }

  .report h2 {
    font-size: 24px;
  }

  .userright h2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .pagination-custom {
    margin-top: 20px;
  }

  input::placeholder {
    font-size: 12px;
  }

  .pagination-custom
    .MuiPagination-root.MuiPagination-root
    .MuiPaginationItem-root {
    font-size: 14px;
  }

  .resetButton {
    font-size: 14px !important;
  }

  .csvButton {
    font-size: 14px !important;
  }

  .report h2 {
    font-size: 20px;
  }

  .userright h2 {
    font-size: 20px;
  }

  .userright p {
    font-size: 12px;
  }
}
