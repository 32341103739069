.text-start {
    text-align: left !important
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.form-control.css-1tcs2i1-MuiInputBase-root-MuiInput-root {
    padding: 0!important;
    background: transparent;
}
.css-1tcs2i1-MuiInputBase-root-MuiInput-root:before {
    border: 0!important;
}
.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-decoration-underline {
    text-decoration: underline !important
}

.text-decoration-line-through {
    text-decoration: line-through !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important
}
.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}
.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

@media (min-width:992px) {
    
    .m-lg-0 {
        margin: 0 !important
    }
    .m-lg-1 {
        margin: .25rem !important
    }
    .m-lg-2 {
        margin: .5rem !important
    }
    .m-lg-3 {
        margin: 1rem !important
    }
    .m-lg-4 {
        margin: 1.5rem !important
    }
    .m-lg-5 {
        margin: 3rem !important
    }
    .m-lg-auto {
        margin: auto !important
    }
    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }
    .mx-lg-1 {
        margin-right: .25rem !important;
        margin-left: .25rem !important
    }
    .mx-lg-2 {
        margin-right: .5rem !important;
        margin-left: .5rem !important
    }
    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important
    }
    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important
    }
    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important
    }
    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important
    }
    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }
    .my-lg-1 {
        margin-top: .25rem !important;
        margin-bottom: .25rem !important
    }
    .my-lg-2 {
        margin-top: .5rem !important;
        margin-bottom: .5rem !important
    }
    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important
    }
    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important
    }
    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important
    }
    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important
    }
    .mt-lg-0 {
        margin-top: 0 !important
    }
    .mt-lg-1 {
        margin-top: .25rem !important
    }
    .mt-lg-2 {
        margin-top: .5rem !important
    }
    .mt-lg-3 {
        margin-top: 1rem !important
    }
    .mt-lg-4 {
        margin-top: 1.5rem !important
    }
    .mt-lg-5 {
        margin-top: 3rem !important
    }
    .mt-lg-auto {
        margin-top: auto !important
    }
    .me-lg-0 {
        margin-right: 0 !important
    }
    .me-lg-1 {
        margin-right: .25rem !important
    }
    .me-lg-2 {
        margin-right: .5rem !important
    }
    .me-lg-3 {
        margin-right: 1rem !important
    }
    .me-lg-4 {
        margin-right: 1.5rem !important
    }
    .me-lg-5 {
        margin-right: 3rem !important
    }
    .me-lg-auto {
        margin-right: auto !important
    }
    .mb-lg-0 {
        margin-bottom: 0 !important
    }
    .mb-lg-1 {
        margin-bottom: .25rem !important
    }
    .mb-lg-2 {
        margin-bottom: .5rem !important
    }
    .mb-lg-3 {
        margin-bottom: 1rem !important
    }
    .mb-lg-4 {
        margin-bottom: 1.5rem !important
    }
    .mb-lg-5 {
        margin-bottom: 3rem !important
    }
    .mb-lg-auto {
        margin-bottom: auto !important
    }
    .ms-lg-0 {
        margin-left: 0 !important
    }
    .ms-lg-1 {
        margin-left: .25rem !important
    }
    .ms-lg-2 {
        margin-left: .5rem !important
    }
    .ms-lg-3 {
        margin-left: 1rem !important
    }
    .ms-lg-4 {
        margin-left: 1.5rem !important
    }
    .ms-lg-5 {
        margin-left: 3rem !important
    }
    .ms-lg-auto {
        margin-left: auto !important
    }
    .p-lg-0 {
        padding: 0 !important
    }
    .p-lg-1 {
        padding: .25rem !important
    }
    .p-lg-2 {
        padding: .5rem !important
    }
    .p-lg-3 {
        padding: 1rem !important
    }
    .p-lg-4 {
        padding: 1.5rem !important
    }
    .p-lg-5 {
        padding: 3rem !important
    }
    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important
    }
    .px-lg-1 {
        padding-right: .25rem !important;
        padding-left: .25rem !important
    }
    .px-lg-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }
    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important
    }
    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    .py-lg-1 {
        padding-top: .25rem !important;
        padding-bottom: .25rem !important
    }
    .py-lg-2 {
        padding-top: .5rem !important;
        padding-bottom: .5rem !important
    }
    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important
    }
    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important
    }
    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important
    }
    .pt-lg-0 {
        padding-top: 0 !important
    }
    .pt-lg-1 {
        padding-top: .25rem !important
    }
    .pt-lg-2 {
        padding-top: .5rem !important
    }
    .pt-lg-3 {
        padding-top: 1rem !important
    }
    .pt-lg-4 {
        padding-top: 1.5rem !important
    }
    .pt-lg-5 {
        padding-top: 3rem !important
    }
    .pe-lg-0 {
        padding-right: 0 !important
    }
    .pe-lg-1 {
        padding-right: .25rem !important
    }
    .pe-lg-2 {
        padding-right: .5rem !important
    }
    .pe-lg-3 {
        padding-right: 1rem !important
    }
    .pe-lg-4 {
        padding-right: 1.5rem !important
    }
    .pe-lg-5 {
        padding-right: 3rem !important
    }
    .pb-lg-0 {
        padding-bottom: 0 !important
    }
    .pb-lg-1 {
        padding-bottom: .25rem !important
    }
    .pb-lg-2 {
        padding-bottom: .5rem !important
    }
    .pb-lg-3 {
        padding-bottom: 1rem !important
    }
    .pb-lg-4 {
        padding-bottom: 1.5rem !important
    }
    .pb-lg-5 {
        padding-bottom: 3rem !important
    }
    .ps-lg-0 {
        padding-left: 0 !important
    }
    .ps-lg-1 {
        padding-left: .25rem !important
    }
    .ps-lg-2 {
        padding-left: .5rem !important
    }
    .ps-lg-3 {
        padding-left: 1rem !important
    }
    .ps-lg-4 {
        padding-left: 1.5rem !important
    }
    .ps-lg-5 {
        padding-left: 3rem !important
    }
    .text-lg-start {
        text-align: left !important
    }
    .text-lg-end {
        text-align: right !important
    }
    .text-lg-center {
        text-align: center !important
    }
}