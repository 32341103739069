.positionReltive {
  position: relative;
}

.search_results_box {
  background: white;
  box-shadow: 1px 10px 16px #5a5959;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  width: 100%;
  z-index: 99;
  right: 0px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.search_results {
  cursor: pointer;
  padding: 5px;
}

.search_results_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.search_results_box::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.search_results_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.search_results:hover {
  background-color: #d5e3f7;
}

.cancel_popup {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.cancel_popup p {
  display: block;
  color: #121212;
  opacity: 0.5;
  cursor: pointer;
  text-transform: uppercase;
  font-family: Bahnschrift;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
}