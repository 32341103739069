.formGroup label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
  display: block;
}

.formGroup {
  margin-bottom: 5px;
}

.formControl {
  background: #f0f0f0;
  border-radius: 10px;
  border: 0px;
  padding: 13px 15px;
  width: 100%;
  height: 42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;

  letter-spacing: 0.02em;

  color: #848484;
  outline: none;
}
.showButton {
  width: 225px;
  margin: auto;
}
.Mt_41 {
  margin-top: 41px;
}

@media screen and (max-width: 992px) {
  .formGroup label {
    font-size: 14px;
  }

  .formControl {
    width: 100%;
    height: 46px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .formGroup label {
    font-size: 12px;
  }

  .formControl {
    font-size: 12px;
  }
}
