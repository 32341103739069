.date_picker_error fieldset,
.date_picker_error label {
  border-color: #ccc !important;
  color: black !important;
}

.search_box {
  background: #ffffff;
  border: 1px solid #cacaca;
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  padding: 10px 10px 10px 12px;
  color: #848484;
}

.FormControl {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #848484;
  outline: none;
  border: 0;
}
.btn {
  background: #d5e3f7;
  border-radius: 33px;
  outline: none;
  border: 0;
  padding: 5px 20px;
  color: #274593;
  cursor: pointer;
  line-height: 4px;
}
.btn:hover {
  background: #cddff7;
}
.widthinput {
  width: 100%;
}
.widthinput .FormControl {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .FormControl {
    font-size: 14px;
  }
}
