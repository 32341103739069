@font-face {
    font-family: 'Bahnschrift';
    src: url('../fonts/bahnschrift.eot');
    src: url('../fonts/bahnschrift.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bahnschrift.woff2') format('woff2'),
         url('../fonts/bahnschrift.woff') format('woff'),
         url('../fonts/bahnschrift.ttf') format('truetype'),
         url('../fonts/bahnschrift.svg#Bahnschrift') format('svg');
    font-weight: normal;
    font-style: normal;
}