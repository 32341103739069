.title h2 {
  font-family: "Bahnschrift";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #121212;
  margin: 0px;
  margin-top: 10px;
}

.subtitle h3 {
  margin: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #121212;
}

.imageBox {
  position: relative;
}

.ql-editor {
  font-family: 'Poppins' !important;
}

.imageBox img {
  border-radius: 5px;
  display: block;
  width: 100%;
}

.settingDescription h2 {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;

  color: #121212;
}

.settingDescription p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #121212;
  opacity: 0.7;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.playButton {
  position: absolute;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  background: #0000003d;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showButton {
  width: 188px;
  margin: auto;
  padding-bottom: 30px;
}

.spaceMy {
  margin: 0px 0px 10px 0px;
}

.spaceMy input::placeholder {
  font-size: 20px;
  font-weight: 500;
}

.postTile {
  background: #f0f0f0;
  border-radius: 10px;
  outline: none;
  width: 100%;
  border: 0px;
  padding: 11px 15px;
  height: 42px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;

  letter-spacing: 0.02em;

  color: #121212;
}

.postTile::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #848484;
}

.postTile::-moz-placeholder {
  /* Firefox 19+ */
  color: #848484;
}

.postTile:-ms-input-placeholder {
  /* IE 10+ */
  color: #848484;
}

.postTile:-moz-placeholder {
  /* Firefox 18- */
  color: #848484;
}

.newpostDescripition {
  background: #f0f0f0;
  border-radius: 10px;
  padding: 11px 15px;
}

.textareaFormat {
  background: #ffffff;
  border-radius: 6px;
  padding: 5px 10px;
  height: 34px;
}

.textareaFormat span {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.newpostContent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  letter-spacing: 0.02em;

  /* Black #121212 */
  color: #121212;
}

.textarea {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  letter-spacing: 0.02em;

  /* Dark grey */
  color: #848484;
  width: 100%;
  border: 0px;
  background: transparent;
  resize: none;
  outline: none;
}

.addbtnlink {
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .title h2 {
    font-size: 24px;
  }

  .spaceMy input::placeholder {
    font-size: 16px;
  }

  .subtitle h3 {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .title h2 {
    font-size: 20px;
  }

  .subtitle h3 {
    font-size: 14px;
  }

  .settingDescription p {
    font-size: 12px;
  }
}