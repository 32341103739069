.user h2 {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
@media screen and (max-width: 992px) {
  .user h2 {
    font-size: 20px;
  }

  .userCounter p {
    font-size: 12px !important;
  }
}

.userCounter {
  background: #eea23d;
  border-radius: 80px;
  box-shadow: 0px 5px 25px rgb(252 183 79 / 50%);

  padding: 20px 25px;
  font-family: "Bahnschrift";
  color: #ffffff;
}

.userCounter p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.marginBottom {
  margin-bottom: 24px;
}

@media screen and (max-width: 992px) {
  .user h2 {
    font-size: 20px;
  }

  .userCounter p {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 480px) {
  .user h2 {
    font-size: 16px;
  }

  .userCounter p {
    font-size: 10px !important;
  }
}
