.placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #848484;
}

@media screen and (max-width: 480px) {
  .placeholder {
    font-size: 14px;
  }
}
